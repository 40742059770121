@use "sass:math";

.mural-v1_5 {
  @import "../shared/_defaults";
  @import "../v1.0/modal";
  @include origin-flag-tagboard-classic;

  .posts {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    perspective-origin: center;
    perspective: 1920px;

    .post {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: rgba($black, .5);
    }

    .media-module {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      overflow: hidden;
    }

    .media {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // POST MODAL

  .post-modal {
    .post {
      @include simple-center-align;
      width: 80%;
      height: 80%;
    }
  }

  // GRID LAYOUTS

  .grid-cell {
    position: relative;
    float: left;
  }

  .post-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @for $i from 1 through 50 {
    .grid-columns-#{$i} {
      .grid-cell {
        width: math.div(100%, $i);
      }
    }

    .grid-rows-#{$i} {
      .grid-cell {
        height: math.div(100%, $i);
      }
    }
  }

  // POST DETAIL MODAL
  .post-detail-modal {
    transition: opacity .6s ease;

    .post {
      transition: all .6s ease;
      transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
    }
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .post,
  .modal-leave-active .post {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  .backdrop {
    opacity: .7;
    pointer-events: none;
  }

  // CUSTOMIZATIONS

  .hide-media-captions .post-detail-modal {
    .post {
      width: 80vmin;
      height: 80vmin;
    }

    .media-module,
    .blurred-media-module {
      width: 100%;
    }

    .has-media {
      color: $white;

      .text-module {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        background: linear-gradient(to top, rgba($black, .8), rgba(black, 0));
      }

      .meta-author {
        padding-right: 0;
        margin-bottom: 0;
      }

      .message {
        display: none;
      }
    }
  }

  .crop-images {
    .media-module {
      background-size: cover;
    }

    .media {
      object-fit: cover;
    }
  }

  .show-blurred-images {
    .blurred-media-module {
      display: block;
    }
  }

  .post-color-theme-dark {
    .post {
      color: $white;
    }

    .text-module {
      background-color: rgba($black, .5);
    }
  }
}
